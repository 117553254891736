/*!
 * Start Bootstrap - Small Business (https://startbootstrap.com/template-overviews/small-business)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-small-business/blob/master/LICENSE)
 */


/*
 * Globals
 */

/* Links */
a,
a:focus,
a:hover {
  color: #333; /* IE8 proofing */
  color: rgba(0,0,0,.5);
}

/* Custom default button */
.btn-default,
.btn-default:hover,
.btn-default:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritence from `body` */
  background-color: #fff;
  border: 1px solid #fff;
}


/*
 * Base structure
 */

html,
body {
  height: 100%;
  background-color: #fc4c02;
  color: #333;
  text-align: center;
}
.activity-summary{
  text-align: left;
  margin: 10px;
  border-radius: 10px;
}

/*
 * Content Structure
 */

.app-container {
  margin-top: 30px;
  background-color: #FFF;
}

/* Extra markup and styles for table-esque vertical and horizontal centering */
.site-wrapper {
  display: table;
  width: 100%;
  height: 100%; /* For at least Firefox */
  min-height: 100%;
  -webkit-box-shadow: inset 0 0 100px rgba(0,0,0,.5);
          box-shadow: inset 0 0 100px rgba(0,0,0,.5);
}
.site-wrapper-inner {
  display: table-cell;
  vertical-align: top;
}
.cover-container {
  margin-right: auto;
  margin-left: auto;
}

/* Padding for spacing */
.inner {
  padding: 30px;
}

/*
 * Footer
 */

.mastfoot {
  color: #000; /* IE8 proofing */
}


/*
 * Modal
 */

 .modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 10px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * Affix and center
 */
  
  @media (min-width: 768px) {
    /* Handle the widths */
    .masthead,
    .mastfoot,
    .cover-container {
      width: 100%; /* Must be percentage or pixels for horizontal alignment */
    }
  }
  
  @media (min-width: 992px) {
    .masthead,
    .mastfoot,
    .cover-container {
      width: 700px;
    }
    .app-container {
      border-radius: 25px;
    }
  }

/*
 * Activity table smaller text on mobile
 */

 @media (max-width: 499px) {
  /* Handle the widths */
  .activity-table {
    font-size: 80%;
  }
  .activity-summary{
    margin-left: 2px;
    margin-right: 2px;
  }
  .inner {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (max-width: 425px) {
  /* Handle the widths */
  .activity-table {
    font-size: 60%;
  }
  .activity-hide-small {
    display: none;
  }
}